import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

import ImageQuery from "../../../types/image";

const imgQuery = graphql`
  query {
    # 4860w x 3240h
    BLUE: file(relativePath: { eq: "patterns/BLUE_PRIMARY.png" }) {
      childImageSharp {
        fluid(maxWidth: 486, maxHeight: 324) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    GREEN: file(relativePath: { eq: "patterns/GREEN_PRIMARY.png" }) {
      childImageSharp {
        fluid(maxWidth: 486, maxHeight: 324) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Container = styled.div<{ color: string }>`
  border-radius: var(--ml);
  padding: var(--xl);
  background-color: ${({ color }) => color};
  max-height: 300px;
  width: 100%;
  box-shadow: var(--shadowM);
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: box-shadow 100ms linear;

  &:hover {
    box-shadow: var(--shadowXS);
  }
`;

const Label = styled.h1`
  font-weight: var(--xBold);
  font-size: 1.5rem;
  margin-bottom: var(--m);
  z-index: 1;
  position: relative;
`;

const Description = styled.p`
  font-size: 1.15rem;
  line-height: 1.25rem;
  padding-bottom: var(--m);
  max-width: 400px;
  z-index: 1;
  position: relative;
`;

const Button = styled.div`
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  font-weight: var(--bold);
  padding: var(--ml) var(--m);
  min-width: 80px;
  width: min-content;
  text-align: center;
  border-radius: var(--s);
`;

const ImageContainer = styled.figure`
  width: 100%;
  height: 100%;
  max-height: 180px;
  max-width: 270px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  opacity: 0.25;
`;

interface CardImageQuery {
  BLUE: ImageQuery["file"];
  GREEN: ImageQuery["file"];
}

interface CardProps {
  index: number;
  label: string;
  description: string;
}

const Card = ({ index, label, description }: CardProps) => {
  const query: CardImageQuery = useStaticQuery(imgQuery);

  return (
    <Container
      color={index % 2 !== 0 ? "var(--vatsimBlue)" : "var(--vatsimGreen)"}
    >
      <Label>{label}</Label>
      <Description>{description}</Description>
      <Button>Go To</Button>
      <ImageContainer>
        <Img
          fluid={
            index % 2 !== 0
              ? query.BLUE.childImageSharp.fluid
              : query.GREEN.childImageSharp.fluid
          }
        />
      </ImageContainer>
    </Container>
  );
};

export default Card;
