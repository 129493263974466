import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import Card from "../components/Docs/Card";
import Header from "../components/Header";
import SideNav, { MobileButton } from "../components/Docs/SideNav";
import SEO from "../components/Seo";
import { M_BREAKPOINT, S_BREAKPOINT } from "../styles";
import Navigation from "../components/Docs/Navigation";
import { DOCS_HEADER_HEIGHT } from "../components/Header/Header";

const Container = styled.div`
  overflow-x: hidden;
  height: 100vh;
  background-color: var(--primary);
`;

const ContentContainer = styled.section`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: calc(var(--m) + ${DOCS_HEADER_HEIGHT});
`;

const Content = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 100%;
  padding: 0 var(--sGutter);
  padding-top: var(--xl);
`;

const Heading = styled.h1`
  font-weight: var(--xBold);
  text-transform: capitalize;
  font-size: 2.5rem;
  margin-bottom: var(--l);

  @media (max-width: ${M_BREAKPOINT}px) {
    margin-top: 0;
    font-size: 1.85rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  line-height: 1.5em;
  margin-bottom: var(--xl);
  max-width: 1000px;
`;

const CardContainer = styled.div`
  display: flex;
  position: relative;

  & > a {
    width: 100%;
  }

  & > :first-child {
    margin-right: var(--l);
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    flex-direction: column;

    & > :first-child {
      margin-right: 0;
      margin-bottom: var(--l);
    }
  }
`;

const DocsPage = () => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [headerNavOpen, setHeaderNavOpen] = useState(false);

  useEffect(() => {
    headerNavOpen && setSideNavOpen(false);
  }, [setSideNavOpen, headerNavOpen]);

  useEffect(() => {
    sideNavOpen && setHeaderNavOpen(false);
  }, [setHeaderNavOpen, sideNavOpen]);

  return (
    <Container>
      <Helmet>
        {/* Preconnect to Algolia Cluster | Improves first query performance */}
        <link rel="preconnect" href="https://BH4D9OD16A-dsn.algolia.net" />
      </Helmet>
      <SEO title="Docs" />
      <MobileButton
        open={sideNavOpen}
        setOpen={() => setSideNavOpen(!sideNavOpen)}
      />
      <Header
        open={headerNavOpen}
        setOpen={() => setHeaderNavOpen(!headerNavOpen)}
        headerContent={<Navigation />}
      />
      <ContentContainer>
        <SideNav open={sideNavOpen} />
        <Content>
          <Heading>Welcome to VATSIM's Documentation</Heading>
          <Description>
            VATSIM is the free to use, international online flying network. Here
            you may find documentation pertaining to general VATSIM usage,
            membership and technical topics. For flight training documentation
            and other information check the links below
          </Description>
          <CardContainer>
            <a href="https://vatsim.net/docs/basics/getting-started/">
              <Card
                label="Getting Started"
                description="Quick guide to getting started as a VATSIM pilot"
                index={0}
              />
            </a>
            <a href="https://my.vatsim.net/learn">
              <Card
                label="Pilot Learning Center"
                description="Learn more about flying on the VATSIM network"
                index={1}
              />
            </a>
          </CardContainer>
        </Content>
        <div />
      </ContentContainer>
    </Container>
  );
};

export default DocsPage;
