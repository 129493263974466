import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        url
        twitterUsername
      }
    }
  }
`;

interface SeoQuery {
  siteMetadata: {
    defaultTitle: string;
    titleTemplate: string;
    defaultDescription: string;
    url: string;
    twitterUsername: string;
  };
}

interface SEOProps {
  title?: string;
  description?: string;
}

const SEO = ({ title, description }: SEOProps) => {
  const { pathname } = useLocation();
  const { site }: { site: SeoQuery } = useStaticQuery(query);

  const {
    defaultTitle,
    defaultDescription,
    url,
    twitterUsername,
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${url}${pathname}`,
  };

  return (
    <Helmet
      title={`${seo.title[0].toUpperCase() + seo.title.slice(1)} | VATSIM`}
    >
      <meta name="description" content={seo.description} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
    </Helmet>
  );
};

export default SEO;
